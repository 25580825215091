<template>
  <div class="payment">
    <Loading :spin="progressing || isInitializing" />

    <!-- Header -->
    <OrderHeader :showGoBack="showBack" />
    <!-- Footer -->
    <section class="foot">
      <!-- <button :class="{ disabled: progressing }" @click="onPayClick">
        <span> {{ $t("支付") }} </span>
        <span> NT$ {{ payableAmount }} </span>
        <span v-if="pointAmount"> + </span>
        <img v-if="pointAmount" :src="selectedPoint.pointLogoW" />
        <span v-if="pointAmount">{{ pointAmount }}</span>
      </button> -->
      <button
        :class="{ disabled: progressing || disabledPayButton }"
        @click="onPayClick"
      >
        <img class="action__img" :src="selectedPaymentMethod.iconW" />
        <font-awesome-icon
          v-if="selectedPaymentMethod.paymentService === 'offline'"
          icon="fa-solid fa-money-bill-wave"
        />
        <div class="action__content">
          <!-- <div class="action__subtotal">
            <img src="@/assets/icon/pCreditW.svg" />
            <span> {{ formatCurrency(payableAmount, null) }} </span>
            <span v-if="usedPoints"> + </span>
            <img
              v-if="usedPoints"
              :src="selectedPoint.pointLogoW"
            />
            <span v-if="usedPoints"> {{ usedPoints }} </span>
            <span v-if="ticketDiscount">+</span>
            <img v-if="ticketDiscount" src="@/assets/icon/pCouponW.svg" />
            <span v-if="ticketDiscount">{{formatCurrency(ticketDiscount, null) }}</span>
          </div> -->
          <span class="action__total">
            {{ formatCurrency(payableAmount, currencySettings) }}</span
          >
        </div>
        <div class="action__name">
          <span v-if="payableAmount && !noPaymentServices">{{
            $t("Pay")
          }}</span>
          <span v-else>{{ $t("Direct discount") }}</span>
        </div>
      </button>
    </section>

    <!-- Main -->
    <section class="main">
      <Discounts :no-payment-services="noPaymentServices"/>
      <!-- Payment -->
      <AvailablePaymentMethods
        v-if="!noPaymentServices"
        :has-system-notice="hasSystemNotice"
        @selected="onSelectPaymentMethod"
        @noticed="openSysNoticePopup"
      />
      <section
        class="policy"  
        v-if="noPaymentServices && isSSOReady && ssoAppId && !isLoggedIn"
      >
        <h1> {{ $t("Login to proceed") }} </h1>
      </section>
      <section class="policy">
        <span class="policy--gray">
          {{
            $t("Complete the payment within the time", { time: expiredTime })
          }}
        </span>
        <span>{{ $t("Service Description") }}</span>
      </section>
    </section>

    <!-- Popup (Order) -->
    <PopupOrder />

    <!-- Popup (Refund) -->
    <PopupRefund />

    <PopupSystemNotice
      :message="popupSystem.message"
      :close-callback="popupSystem.closeFunc"
      @close="closeSysNoticePopup"
    />
    <!-- Popup (Notification) -->
    <PopupNotice
      :title="popup.title"
      :notice-type="popup.noticeType"
      :message="popup.message"
      :ok-btn="popup.okBtn"
      :cancel-btn="popup.cancelBtn"
      :show-cancel="popup.showCancelBtn"
      :cancel-callback="popup.cancelFunc"
      :ok-callback="popup.okFunc"
      @close="closePopup"
    />
    <PopupBarcode v-if="showBarcode" :card-no="userCarrier" />

    <PopupLogout v-if="showLogout" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from "vuex";
// import SignInButton from '@/components/SignInButton.vue';
import PopupNotice from "@/components/PopupNotice.vue";
import AvailablePaymentMethods from "@/components/AvailablePaymentMethods.vue";
// import AvailablePointList from '@/components/AvailablePointList.vue';
import Discounts from "@/components/Discounts.vue";

import OrderHeader from "@/components/OrderHeader.vue";
import PopupOrder from "@/components/PopupOrder.vue";
import PopupRefund from "@/components/PopupRefund.vue";
import PopupSystemNotice from "@/components/PopupSystemNotice.vue";
// import PopupApple from '@/components/PopupApple.vue';
import PopupBarcode from "@/components/PopupBarcode.vue";
import PopupLogout from "@/components/PopupLogout.vue";

import Loading from "@/components/Loading.vue";
import moment from "moment-timezone";
import {
  /* fetchSystemNotice, */ fetchSystemStatus,
  fetchUserPoints,
  fetchOrderInfo,
} from "@/apis/fetchData";
import {
  generateRequest,
  /* payByPoints, */
  payForOrder,
  rollbackByRequestId,
} from "@/apis/updateData";
import DDPG from "@/mixins/ddpg";
import { formatCurrency } from "@/utils/utils";

export default {
  name: "Order",
  mixins: [DDPG],
  components: {
    // SignInButton,
    PopupNotice,
    AvailablePaymentMethods,
    // AvailablePointList,
    Discounts,
    OrderHeader,
    PopupOrder,
    PopupRefund,
    PopupSystemNotice,
    PopupBarcode,
    PopupLogout,
    // PopupApple,
    Loading,
  },
  // beforeRouteEnter(to, from, next) {
  //   // called before the route that renders this component is confirmed.
  //   // does NOT have access to `this` component instance,
  //   // because it has not been created yet when this guard is called!
  //   // console.log(to, from);
  //   next((vm) => {
  //     // get system status;
  //     fetchSystemStatus()
  //       .then((res) => {
  //         console.log('fetchSystemStatus done', res);
  //         vm.$store.commit('updateSystemStatus', res.data);
  //       })
  //       .catch((e) => {
  //         console.error('fetchSystemStatus failed', e);
  //         vm.$store.commit('updateSystemStatus', null);
  //       })
  //       .finally(() => {
  //         // vm.$store.commit('updateSystemStatus', {disablePaymentServices: [ 'cardpay' ]});
  //         next();
  //       });

  //   })
  // },
  data() {
    return {
      formatCurrency: formatCurrency,
      progressing: false,
      // selectedPoint: {},
      // selectedTicket: {},
      selectedPaymentMethod: {},
      // pointAmount: 0, // 點數折抵金額
      // unmaskedPointAmount: null, // 點數折抵金額
      toggleAllPoints: false, // 使用全部點數
      paymentInfo: {
        memberId: undefined,
        requestId: null,
        points: null,
        tickets: null,
        payment: null,
      },
      popup: {
        type: null,
        title: null,
        message: null,
        okBtn: null,
        cancelBtn: null,
        showCancelBtn: false,
        cancelFunc: null,
        okFunc: null,
      },
      popupSystem: {
        title: null,
        message: null,
        closeFunc: null,
      },
      // showTappayCardForm: null,
      // showCardpayCardForm: null,
      lastErrorRequestId: null, // OR005 error rollback時用
      systemNotice: {},
      payAfterClickingConfirmBtn: true, // 信用卡確認後直接付款
      creditCardError: null,
      isInitializing: false, // init apple pay or google pay
      paymentIndex: null,
      timeoutID: null
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.isLoggedIn,
      ssoUser: (state) => state.user,
      order: (state) => state.orderInfo,
      // amount: (state) => state.amount,
      isSSOReady: (state) => state.isSSOReady,
      isSdkInit: (state) => state.sdkInitialized,
      ssoAppId: (state) => state.ssoAppId,
      shopSupportedPoints: (state) => state.supportedPoints,
      showBarcode: (state) => state.showUserCarrier,
      showLogout: (state) => state.showLogout,
    }),
    ...mapGetters({
      // isTokenExpired: 'isTokenExpired',
      userId: "getUserId",
      memberId: "getMemberId",
      shopId: "getShopId",
      shopName: "getShopName",
      merchantId: "getMerchantId",
      amount: "getAmount", // 訂單金額
      selectedPoint: "getSelectedPoint",
      selectedCard: "getSelectedCard",
      selectedCoupon: "getSelectedCoupon",
      // discounts: "getDiscounts",
      ticketDiscount: "getTicketDiscounts",
      thirdPartyMember: "getThirdPartyMemberShip",
      portalInfo: "portal/getPortalInfo"
      // paymentMethods: 'getSupportedPaymentMethods'
    }),
    // supportedPaymentMethods () {
    //   return this.paymentMethods.filter(method => method.isBrowserSupported && method.isEnabled );
    // },
    noPaymentServices() {
      return this.portalInfo?.disableOnlinePayment || this.order?.options?.noPaymentServices;
    },
    currencySettings() {
      return this.order?.orderInfo?.currencySettings || null;
    },
    userCarrier() {
      if (this.ssoUser?.user?.cardNo) {
        return this.ssoUser.user.cardNo.trim();
      }
      return "";
    },
    showBack() {
      return false;
      // return window.history.length > 3;
    },
    showPayer() {
      if (
        this.billingInfo &&
        (this.billingInfo.name || this.billingInfo.phone)
      ) {
        if (this.billingInfo.name === "Anon****s") {
          return false;
        } else {
          return true;
        }
      }
      return false;
    },
    hasSystemNotice() {
      return this.systemNotice?.message ? true : false;
    },
    systemMessage() {
      return this.systemNotice?.message || "";
    },
    token() {
      return this.$route.params.token;
    },
    billingInfo() {
      return this.order.billingInfo || {};
    },
    expiredTime() {
      return this.order.expirationTs
        ? moment.unix(this.order.expirationTs).format("MM/DD HH:mm")
        : null;
    },
    payableAmount() {
      // 線上支付應付金額
      const payable = this.amount - this.pointAmount - this.ticketDiscount;
      return Math.max(payable, 0);
    },
    maxPointAmount() {
      return Math.min(this.selectedPoint.redeemableDollars, this.amount) || 0;
    },
    pointAmount() {
      return this.selectedPoint?.usingPoints &&
        this.selectedPoint.pointsToCurrency
        ? (this.selectedPoint.usingPoints /
            this.selectedPoint.pointsToCurrency.points) *
            this.selectedPoint.pointsToCurrency.currency
        : this.selectedPoint?.usingPoints
        ? this.selectedPoint?.usingPoints
        : 0;
    },
    usedPoints() {
      return this.selectedPoint?.usingPoints || 0;
      // return this.selectedPoint && this.selectedPoint.pointsToCurrency
      //   ? (this.pointAmount / this.selectedPoint.pointsToCurrency.currency) *
      //       this.selectedPoint.pointsToCurrency.points
      //   : this.pointAmount;
    },
    // showPointsSection() {
    //   if (
    //     this.isSSOReady &&
    //     this.ssoAppId &&
    //     this.isLoggedIn &&
    //     this.shopSupportedPoints.length
    //   ) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // showTicketsSection() {
    //   if (this.isSSOReady && this.ssoAppId && this.isLoggedIn) {
    //     return false;
    //   } else {
    //     return false;
    //   }
    // },
    disabledPayButton() {
      if (
        this.payableAmount > 0 &&
        !this.noPaymentServices &&
        !this.selectedPaymentMethod?.paymentService
      ) {
        return true;
      } else if (this.noPaymentServices && !(this.selectedCoupon || this.selectedPoint)) {
        return true;
      } else {
        return false;
      }
    },
    partnerCode () {
      return this.orderInfo?.member?.thirdPartyPartnerCode || null;
    },
    // ticketDiscount () {
    //   let initDiscount = 0;
    //   const discountAmount = this.discounts.reduce(
    //     (accumulator, current) => accumulator + current.amount,
    //     initDiscount
    //   );
    //   return discountAmount;
    // }
  },
  watch: {
    memberId: {
      handler(newVal) {
        if (newVal) {
          this.setDdpayMember(newVal);
          // this.setMemberId(newVal);
        } else {
          this.setDdpayMember("Guest");
          // this.setMemberId("Guest");
        }
      },
    },
    payableAmount: {
      handler(val) {
        if (val > 0) {
          this.init3rdPartyPayment();
        }
      },
    },
  },
  mounted() {
    let config = {
      mode: process.env.VUE_APP_ENV === "production" ? "production" : "sandbox",
      appId: process.env.VUE_APP_DDPG_APP_ID,
      appKey: process.env.VUE_APP_DDPG_APP_KEY,
      tappayAppId: 12867,
      tappayAppKey:
        "app_8rd2JX9GLGqoNPZPo07DI9XJP1n2X6klgOJZj5Y8eHggDTIjm44fR5UJCeJe",
      cardpayApplePayMerchantId: process.env.VUE_APP_APPLE_PAY_MERCHANT_ID,
      tappayApplePayMerchantId:
        process.env.VUE_APP_TAPPAY_APPLE_PAY_MERCHANT_ID,
      cardpayGooglePayMerchantId:
        process.env.VUE_APP_CARDPAY_GOOGLE_PAY_MERCHANT_ID,
      tappayGooglePayMerchantId:
        process.env.VUE_APP_TAPPAY_GOOGLE_PAY_MERCHANT_ID,
      merchantId: this.shopId,
      merchantName: this.shopName,
      memberId: this.memberId || "Guest",
      name: this.billingInfo?.name
            ? this.billingInfo.name
            : this.thirdPartyMember?.name
            ? this.thirdPartyMember.name
            : "Guest",
      phone: this.billingInfo?.phone
            ? this.billingInfo.phone
            : this.thirdPartyMember?.phone
            ? this.thirdPartyMember.phone
            : "0987654321",
      email: this.billingInfo?.email
            ? this.billingInfo.email
            : "service@dotdot.cc",
      // name: "Guest",
      // phone: "0987654321",
      // email: "service@dotdot.cc",
    };
    // console.debug('DDPG confg:', config);
    try {
      this.getSystemStatus();
    } catch (error) {
      console.log('failed to get system status:', error);
    }
    this.initDDPGSDK(config);
    // this.getSystemNotice();

    // const self = this;
    // In safari, when pressing back button, page will not reload
    window.onpageshow = function (event) {
      // 用chrome付款時，回上頁偵測到的type是0
      // 用chrome時，這個trigger的時間點要等很久....
      // const entryType = performance.navigation.type;
      // const perfEntries = performance.getEntriesByType('navigation');
      // console.log('Performance.navigation.type:', performance.navigation.type, perfEntries);
      if (event.persisted) {
        window.location.reload();
        // if (entryType === 2)
        //   // window.location.reload();
        //   self.getOrderInfo();
      }

      // const entryType = performance.navigation.type;
      // const perfEntries = performance.getEntriesByType('navigation');
      // // 2 indicates the page was accessed by navigating into the history.
      // if (event.persisted) {
      //   if (entryType === 2 || (perfEntries && perfEntries[0].type === 'back_forward')) {
      //     // window.location.reload();
      //     self.getOrderInfo();
      //   }
      // }
    };
  },
  methods: {
    setDdpayMember (value) {
      console.log('setDdpayMember: ' + value);
      const memberId = value;
      const self = this;
      if (this.isSdkInit) {
        this.setMemberId(memberId);
      } else {
        if (this.timeoutID) {
          clearTimeout(this.timeoutID);
        }
        setTimeout(function(){
          self.timerId = self.setDdpayMember(memberId)
        }, 1000);
      }
    },
    getSystemStatus() {
      //  確認SSO SDK ready
      if (!this.isSSOReady ) {
        console.log('postpone 100ms to get system status');
        setTimeout(this.getSystemStatus, 100);
        return;
      }
      fetchSystemStatus()
        .then((res) => {
          console.log("fetchSystemStatus done", res);
          this.$store.commit("updateSystemStatus", res.data);
          this.systemNotice = res.data?.systemNotices?.length
            ? res.data.systemNotices[0]
            : null;
          // 為避免同時有二個popup，若沒有系統公告，則檢查訂單狀態
          // 若有系統公告，則在關閉公告後檢查訂單狀態
          if (this.systemNotice?.message) {
            const config = {
              message: this.systemNotice.message,
              closeFunc: this.checkOrderStatus,
            };
            this.openSysNoticePopup(config);
          } else {
            this.checkOrderStatus();
          }
        })
        .catch((e) => {
          console.error("fetchSystemStatus failed", e);
          this.$store.commit("updateSystemStatus", null);
          this.checkOrderStatus();
        });
    },
    // getSystemNotice() {
    //   fetchSystemNotice()
    //     .then((response) => {
    //       console.log('getSystemNotice done:' + JSON.stringify(response));
    //       this.systemNotice = response.data;
    //       // 為避免同時有二個popup，若沒有系統公告，則檢查訂單狀態
    //       // 若有系統公告，則在關閉公告後檢查訂單狀態
    //       if (this.systemNotice.message) {
    //         const config = {
    //           message: this.systemNotice.message,
    //           closeFunc: this.checkOrderStatus,
    //         };
    //         this.openSysNoticePopup(config);
    //       } else {
    //         this.checkOrderStatus();
    //       }
    //     })
    //     .catch((e) => {
    //       console.log('fetchSystemNotice error', e);
    //       this.checkOrderStatus();
    //     });
    // },
    getOrderInfo() {
      fetchOrderInfo(this.$route.params.token)
        .then((res) => {
          console.log("fetchOrderInfo done:" + JSON.stringify(res));
          this.$store.commit("updateOrderInfo", res.data);
          // 確認是否有訂單金額
          const amount = this.$store.getters["getAmount"] || 0;
          if (res.data.orderInfo || amount > 0) {
            if (res.data.orderInfo && res.data.orderInfo.amount) {
              this.$store.commit("updateAmount", res.data.orderInfo.amount);
            } else {
              this.$store.commit("updateAmount", amount);
            }
            this.checkOrderStatus();
          } else {
            console.log("order does not exist");
            this.$store.commit("updateAmount", 0);
            this.$router.push("error");
          }
        })
        .catch((e) => {
          console.error("fetchOrderInfo failed", e.response);
          this.$router.push("error");
        });
    },
    checkOrderStatus() {
      // if (!this.sdkInitialized) return;
      let requestId = this.order.requestId;
      let token = this.token;
      // 如果是取消3ds回來的，不彈出信用卡modal
      if (this.$route.query && this.$route.query.retry) {
        let codes = ["051", "055", "056", "057", "058"];
        const errorCode =
          this.$route.query && this.$route.query.resultCode
            ? this.$route.query.resultCode
            : "";
        let appendMsg = "";
        if (codes.includes(errorCode)) {
          appendMsg = "<br/> - " + this.$i18n.t(`cardErrors.c${errorCode}`);
        }
        let popConfig = {
          type: "info",
          message: this.$i18n.t("Cancel credit card transaction") + appendMsg,
        };

        // 直接rollback
        rollbackByRequestId(token, requestId, null).then((res) => {
          console.log(`done rollback ${requestId} `);
          this.$store.commit("updateOrderInfo", res.data);
          this.getUserPoints();
        });
        this.$nextTick(function () {
          let query = Object.assign({}, this.$route.query);
          delete query.retry;
          delete query.resultCode;
          this.$router.replace({ query });
        });
        this.openPopup(popConfig);
        this.progressing = false;
        return;
      }

      if (this.order.state === "requested" && this.order.requestId) {
        console.log("payment progressing");

        const self = this;

        let popConfig = {
          type: "info",
          //title: this.$i18n.t('訊息'),
          message: this.$i18n.t("未完成的付款交易"),
          // message:
          //   '您有尚未完成的付款交易，若確認要重新付款，正在進行中的付款交易將被取消；若進行中的交易有選擇使用點數或優惠券，使用的點數或優惠券將會被退回。',
          okFunc: function () {
            rollbackByRequestId(token, requestId, null).then((res) => {
              console.log(`done rollback ${requestId} `);
              this.$store.commit("updateOrderInfo", res.data);
              self.getUserPoints();
            });
          },
        };

        this.openPopup(popConfig);
        this.progressing = false;
        // return;
      }
    },
    closeSysNoticePopup() {
      this.popupSystem.message = null;
      this.popupSystem.closeFunc = null;
      //每次關閉都會檢查，是否可以避免.....
      // setTimeout(this.checkOrderStatus, 200);
    },
    openSysNoticePopup(config) {
      this.popupSystem.message =
        config && config.message ? config.message : this.systemNotice.message;
      this.popupSystem.closeFunc =
        config && config.closeFunc ? config.closeFunc : null;
      var popup = document.getElementById("popError");
      popup.style.display = "flex";
      document.body.style.overflow = "hidden";
    },
    closePopup() {
      // var popup = document.getElementById('popNotice');
      // popup.style.display = 'none';
      // document.body.style.overflow = 'scroll';
      this.popup.type = null;
      this.popup.title = null;
      this.popup.message = null;
      this.popup.okBtn = null;
      this.popup.cancelBtn = null;
      this.popup.showCancelBtn = false;
      this.popup.cancelFunc = null;
      this.popup.okFunc = null;
    },
    openPopup(config, modalName) {
      this.popup.noticeType = config.type;
      this.popup.title = config.title;
      this.popup.message = config.message;
      this.popup.okBtn = config.okBtn;
      this.popup.cancelBtn = config.cancelBtn;
      this.popup.showCancelBtn = config.showCancelBtn;
      this.popup.cancelFunc = config.cancelFunc;
      this.popup.okFunc = config.okFunc;

      // 預設都是popNotice
      let popupName = modalName || "popNotice";
      var modal = document.getElementById(popupName);
      modal.style.display = "flex";
      document.body.style.overflow = "hidden";
    },
    popOrder() {
      var popOrder = document.getElementById("popOrder");
      popOrder.style.display = "flex";
      document.body.style.overflow = "hidden";
    },
    popRefund() {
      var popRefund = document.getElementById("popRefund");
      popRefund.style.display = "flex";
      document.body.style.overflow = "hidden";
    },
    popConfirm() {
      var popConfirm = document.getElementById("popConfirm");
      popConfirm.style.display = "flex";
    },
    popConfirmClose() {
      var popConfirm = document.getElementById("popConfirm");
      popConfirm.style.display = "none";
    },
    popConfirmSubmit() {
      var popConfirm = document.getElementById("popConfirm");
      popConfirm.style.display = "none";
      this.preparePaymentAsync();
    },
    async init3rdPartyPayment() {
      if (this.initApplePayResult) return;

      const applePayArray = ["tappay-apple", "cardpay-apple"];
      const method =
        this.selectedPaymentMethod?.paymentService +
        "-" +
        this.selectedPaymentMethod?.paymentType;
      if (applePayArray.includes(method)) {
        console.log("trying to init apple pay:" + this.payableAmount);
        this.isInitializing = true;
        try {
          let initApplePayResult = await this.initApplePay(
            this.selectedPaymentMethod?.paymentService,
            this.payableAmount
          );
          console.log(`sdk.initApplePay`, initApplePayResult);
          this.isInitializing = false;
          return true;
        } catch (err) {
          console.error(`sdk.initApplePay error caught`, err);
          const popConfig = {
            //title: this.$i18n.t('錯誤'),
            type: "applepay",
            title: "",
            message: this.$i18n.t("Not support Apple Pay"),
            // message:
            //   '您的瀏覽器或商家設定不支援Apple Pay，請選擇其他支付方式。',
          };
          this.isInitializing = false;
          this.openPopup(popConfig);
          return false;
        }
      } else {
        return true;
      }
    },
    async onSelectPaymentMethod(data) {
      console.log("onSelectPaymentMethod:" + JSON.stringify(data));
      this.selectedPaymentMethod = data.method;
      // this.showTappayCardForm = data.showTappayCardForm;
      // this.showCardpayCardForm = data.showCardpayCardForm;
      let initPaymentResult = await this.init3rdPartyPayment();
      console.log("[Order] initPayment result: " + initPaymentResult);
      // const applePayArray = ['tappay-apple', 'cardpay-apple'];
      // const method =
      // this.selectedPaymentMethod.paymentService + '-' + this.selectedPaymentMethod.paymentType;
      // if (applePayArray.includes(method)) {
      //   console.log('trying to init apple pay');
      //   try {
      //     let initApplePayResult = await this.initApplePay(this.selectedPaymentMethod.paymentService, this.payableAmount);
      //     console.log(`sdk.initApplePay`, initApplePayResult);

      //   } catch (err) {
      //     console.error(`sdk.initApplePay error caught`, err);
      //     const popConfig = {
      //       //title: this.$i18n.t('錯誤'),
      //       type: 'applepay',
      //       title: '',
      //       message: this.$i18n.t('不支援Apple Pay'),
      //       // message:
      //       //   '您的瀏覽器或商家設定不支援Apple Pay，請選擇其他支付方式。',
      //     };

      //     this.openPopup(popConfig);
      //   }
      // }
      // remove, 不用popup
      // if (this.showTappayCardForm || this.showCardpayCardForm) {
      //   // this.popCredit();
      //   // console.log(this.$route.query?.retry)
      //   let popupName = "popNotice";
      //   let modal = document.getElementById(popupName);
      //   if (
      //     modal.style.display !== "flex" &&
      //     !(this.$route.query && this.$route.query.retry)
      //   ) {
      //     this.popCredit();
      //   }
      //   // 如果是取消3ds回來的，不彈出信用卡modal
      //   // if (!this.$route.query?.retry) {
      //   //   // FIXME: workaround, 當只有一種付款方式時，若沒判斷，會出時彈出信用卡視寫和notice
      //   //   let popupName ='popNotice';
      //   //   var modal = document.getElementById(popupName);
      //   //   if (modal.style.display !== 'flex'){
      //   //     this.popCredit();
      //   //   }
      //   // }
      //   // else {
      //   //   let query = Object.assign({}, this.$route.query);
      //   //   delete query.retry;
      //   //   delete query.resultCode;
      //   //   this.$router.replace({ query });
      //   // }
      // }
    },
    rollback() {
      this.progressing = true;
      const requestId = this.lastErrorRequestId || this.order.requestId;
      console.log(`rollback requestId ${requestId}`);
      const self = this;
      rollbackByRequestId(this.token, requestId, null)
        .then((res) => {
          console.log(`done rollback ${requestId} `);
          console.log(res);
          this.$store.commit("updateOrderInfo", res.data);
          this.getUserPoints();
        })
        //rollback後，直接重新付款（這樣的話UI上顯示的可能是上次付款請求後被圈存完的點數）
        .finally(() => {
          self.preparePaymentAsync();
          // if (!this.lastPaymentInfo) {
          //   this.preparePayment();
          // } else {
          //   this.pay(this.lastPaymentInfo);
          // }
        });
    },
    // 檢查訂單資訊 & 付款方式
    onPayClick() {
      console.log(
        "onPayClick: " + this.amount,
        this.pointAmount,
        JSON.stringify(this.selectedPaymentMethod)
      );
      this.progressing = true;
      // 檢查token是否過期
      // 前端不判斷過期與否，統一由後端
      // const isTokenExpired =
      //   moment().diff(moment.unix(this.order.expirationTs)) > 0;
      // if (isTokenExpired || this.order.state === "expired") {
      //   console.log("expired: " + isTokenExpired);
      //   const self = this;
      //   const popConfig = {
      //     type: "error",
      //     // title: this.$i18n.t('錯誤'),
      //     message: this.$i18n.t("Token expired"),
      //     okFunc: function () {
      //       self.$router.push("error");
      //     },
      //   };
      //   this.openPopup(popConfig);
      //   this.progressing = false;
      //   return;
      // }

      // 檢查是否為無金流訂單，以及是否選擇付款方式
      if (
        this.payableAmount > 0 &&
        !this.noPaymentServices &&
        !this.selectedPaymentMethod?.paymentType
      ) {
        const popConfig = {
          type: "info",
          // title: this.$i18n.t('錯誤'),
          message: this.$i18n.t("Choose payment method"),
        };
        this.openPopup(popConfig);
        this.progressing = false;
        return;
      }

      // 檢查是否為無金流訂單，以及是否選擇使用點券
      if (this.noPaymentServices && !(this.selectedCoupon || this.selectedPoint)) {
        const popConfig = {
          type: "info",
          message: this.$i18n.t("Choose points or coupons"),
        };
        this.openPopup(popConfig);
        this.progressing = false;
        return;
      }

      // 檢查訂單狀態，確認是否付款交易尚未完成
      if (this.order.state === "requested" && this.order.requestId) {
        console.log("payment progressing");
        const popConfig = {
          type: "info",
          // title: this.$i18n.t('訊息'),
          message: this.$i18n.t("outstanding payment transactions"),
          //message:
          //  '您有尚未完成的付款交易，若確認要繼續付款，正在進行中的付款交易將被取消；若進行中的交易有選擇使用點數或優惠券，使用的點數或優惠券將會被退回。',
          okFunc: this.rollback,
        };
        this.openPopup(popConfig);
        this.progressing = false;
        return;
      } else {
        // 看需不需要....多一個popup confirm
        // this.popConfirm();
        this.preparePaymentAsync();
        // this.preparePayment();
      }
    },
    async preparePaymentAsync() {
      /**
       * 0. 取得 pay index
       * 1. 取得request id
       * 2. 若無線上支付，呼叫用點/用券API，capture = auto
       * 3. 若有用點/用券 ＋ 線上支付，，呼叫圈存用點/用券API，capture = 3
       * 5. 呼叫付款 DDPW API
       * */
      this.lastErrorRequestId = null;
      // 無金流訂單，走純點券流程
      // 現金支付，也走純點券流程
      if (this.payableAmount && ! (this.noPaymentServices || this.selectedPaymentMethod?.paymentService === 'offline')) {
        const getPayIndexRes = await this.callGetPayIndexAsync();
        console.log("getPayIndexRes:", getPayIndexRes);
        if (!getPayIndexRes.status) {
          this.openPopup(getPayIndexRes.popConfig);
          return;
        }
      } else {
        this.paymentIndex = undefined;
      }
      
      // 取得request id
      const getRequestIdRes = await this.getRequestIdAsync();
      console.log("getRequestIdRes:", getRequestIdRes);
      if (!getRequestIdRes.status) {
        // console.log(getRequestIdRes.popConfig.okFunc);
        this.openPopup(getRequestIdRes.popConfig);
        return;
      }
      // 帶入payindex
      this.paymentInfo.payment = {
        index: this.paymentIndex,
        amount: this.payableAmount,
        isNonOnlinePayment: this.selectedPaymentMethod?.paymentService === 'offline' || this.noPaymentServices
      };

      console.log("paymentInfo:" + JSON.stringify(this.paymentInfo));
      this.pay();

      // 原本運作的版本
      // if (this.pointAmount) {
      //   // 有使用點數

      //   if (this.payableAmount === 0) {
      //     // 純用點/用券，付款金額=0， 直接用點
      //     console.log("only points, no online payment");
      //     // capture = auto
      //     this.payByPoints();
      //   } else if (this.payableAmount > 0) {
      //     // 點/券 ＋線上支付，確認取得pay index後，圈存
      //     console.log("points + online payment");
      //     // catpute = 3
      //     this.paymentInfo.points.capture = 3;
      //     this.payByPoints();
      //   }
      //   // 用點 API + 線上支付
      //   // this.payByPoints(paymentInfo);
      // } else if (this.payableAmount > 0) {
      //   // 純線上支付
      //   this.pay();
      // }
    },
    async getRequestIdAsync() {
      return generateRequest(this.token, null)
        .then((generateRequestRes) => {
          console.log("generateRequestRes:", generateRequestRes);
          // const requestId = new Date().getTime();
          const requestId = generateRequestRes.data.requestId;

          // let tickets = null;
          this.paymentInfo = {
            member: {
              memberId: this.memberId || undefined,
              uuid: this.userId || undefined,
              merchantId: this.merchantId || undefined,
            },
            requestId,
            points: null,
            tickets: null,
            payment: null,
          };

          if (this.selectedCoupon) {
            // 目前只支援一張，未來可能多張
            this.paymentInfo.tickets = [
              {
                merchantId: this.selectedCoupon.merchantId,
                title: this.selectedCoupon.name,
                ticketId: this.selectedCoupon.ticketId,
                barcodeMessage: this.selectedCoupon.ticketBarcodeMessage,
              },
            ];
          }
          if (this.pointAmount) {
            this.paymentInfo.points = {
              merchantId: this.merchantId,
              receiverEntityType: this.selectedPoint.entityType,
              receiverEntityId: this.selectedPoint.entityId,
              pointCode: this.selectedPoint.pointCode,
              pointName: this.selectedPoint.pointName,
              shopId: this.shopId,
              // capture: "AUTO",
              pointsToCurrency: this.selectedPoint.pointsToCurrency,
              points: this.usedPoints, //預設1:1
              amount: this.pointAmount,
              note: `點數交易 - ${this.shopName} `,
            };

            this.paymentInfo.points.merchantOrderId = requestId;
          }
          return {
            status: true,
            requestId,
          };
        })
        .catch((e) => {
          console.error("generateRequest error:", e.response);
          let popConfig = {
            type: "error",
            message: this.$i18n.t("Unable to generate payment request"),
          };
          if (e.response && e.response.data === "Cannot get by token.") {
            this.$router.push({
              path: "error",
              query: { code: "DDPW001" },
            });
          }
          if (e.response?.data?.error?.code === "OR005") {
            // 例外處理
            this.lastErrorRequestId = e.response.data.error.meta.requestId;
            popConfig = {
              type: "info",
              // title: this.$i18n.t('訊息'),
              message: this.$i18n.t("outstanding payment transactions"),
              // message:
              //   '您有尚未完成的付款交易，若確認要繼續付款，正在進行中的付款交易將被取消；若進行中的交易有選擇使用點數或優惠券，使用的點數或優惠券將會被退回。',
              okFunc: this.rollback,
            };
            this.openPopup(popConfig);
          } else if (e.response?.data?.error?.message) {
            popConfig.message = e.response.data.error.message;
          }
          // this.openPopup(popConfig);
          this.progressing = false;
          return {
            status: false,
            popConfig,
          };
        });
    },
    // 用點或圈存點數
    // payByPoints() {
    //   let points = this.paymentInfo.points;
    //   console.log("payByPoint params:", points);
    //   payByPoints(this.merchantId, this.userId, points)
    //     .then((payByPointsRes) => {
    //       console.log("payByPointsRes:" + JSON.stringify(payByPointsRes));
    //       // 結果回存，debug用
    //       this.paymentInfo.points.transaction = payByPointsRes.data;
    //       // 不論是是圈存，直接付款
    //       this.pay();
    //     })
    //     .catch((e) => {
    //       console.error("payByPoints error:", e.response);
    //       let message = "無法使用點數";
    //       if (
    //         e.response &&
    //         e.response.data &&
    //         e.response.data.error &&
    //         e.response.data.error.message
    //       ) {
    //         message = e.response.data.error.message;
    //       }
    //       const popConfig = {
    //         type: "error",
    //         // title: this.$i18n.t('錯誤'),
    //         message,
    //       };
    //       this.openPopup(popConfig);
    //       // 若是點數不足，重取一次會員點數
    //       this.getUserPoints();
    //       this.progressing = false;
    //     });
    // },
    async callGetPayIndexAsync() {
      console.log("start get pay index async");
      this.progressing = true;
      let paymentMethod = {
        paymentService: this.selectedPaymentMethod.paymentService,
        paymentType: this.selectedPaymentMethod.paymentType,
        amount: this.payableAmount,
        merchantOrderId: this.order.orderId,
        currency: this.currencySettings?.currency || "TWD",
        // 有billing info 才會有付款人資訊
        billingInfo: {
          memberId: this.memberId || "Guest",
          name: this.billingInfo?.name
            ? this.billingInfo.name
            : this.thirdPartyMember?.name
            ? this.thirdPartyMember.name
            : "Guest",
          phone: this.billingInfo?.phone
            ? this.billingInfo.phone
            : this.thirdPartyMember?.phone
            ? this.thirdPartyMember.phone
            : "0987654321",
          email: this.billingInfo?.email
            ? this.billingInfo.email
            : "service@dotdot.cc",
        },
      };

      const supportSaveCardMethods = ["cardpay-credit"];
      const _paymentMethod =
        this.selectedPaymentMethod.paymentService +
        "-" +
        this.selectedPaymentMethod.paymentType;
      if (
        supportSaveCardMethods.includes(_paymentMethod) &&
        this.selectedCard?.cardToken
      ) {
        paymentMethod.cardToken = this.selectedCard.cardToken;
      }
      console.debug("callGetPayIndexAsync:" + JSON.stringify(paymentMethod));
      return this.getPayIndex(paymentMethod)
        .then((res) => {
          console.log("getPayIndex done", res);
          this.paymentIndex = res;
          return {
            status: true,
            paymentIndex: res,
          };
        })
        .catch((e) => {
          console.error("getPayIndex failed", e);
          this.paymentIndex = null;
          const popConfig = {
            type: "error",
            // title: this.$i18n.t('錯誤'),
            message: this.$i18n.t("Unable to generate transaction request"),
          };
          let errorCode = e.code || e.statusCode;
          if (errorCode) {
            switch (errorCode) {
              case "CRDP088":
                popConfig.message = this.$i18n.t("errorCodes.CRDP088");
                break;
              case "CANCELED":
                popConfig.message = this.$i18n.t("errorCodes.CANCELED");
                break;
              default:
                break;
            }
          }
          if (e.response?.data?.error?.message) {
            popConfig.message = e.response.data.error.message;
          } else if (e.msg) {
            popConfig.message = e.msg;
          } else if (e === "Invalid card info.") {
            popConfig.message = this.$i18n.t(
              "Incorrect credit card information"
            );
            // popConfig.okFunc = this.popCredit;
          }
          // this.openPopup(popConfig);
          this.progressing = false;
          return {
            status: false,
            popConfig,
          };
          // 取消圈存，如果有用點或券
        });
    },
    pay() {
      console.log("payByRequest:" + JSON.stringify(this.paymentInfo));
      if (!(this.paymentInfo.orderInfo && this.paymentInfo.orderInfo.amount)) {
        this.paymentInfo.orderInfo = {
          amount: this.amount,
        };
      }
      this.paymentInfo.billingInfo = this.billingInfo;
      payForOrder(this.token, this.paymentInfo)
        .then((response) => {
          console.log("payForOrder:" + JSON.stringify(response));
          // this.lastPaymentInfo = null;
          // this.lastErrorRequestId = null;
          if (response.data && response.data.redirectUrl) {
            window.location.href = response.data.redirectUrl;
            // 這裡沒有設定的話，按上一頁回來時，loader不會結束
            //this.progressing = false;
          }
          setTimeout(function () {
            this.progressing = false;
          }, 100);
          // reset payment
          this.paymentInfo = {
            member: {
              memberId: undefined,
              uuid: undefined,
              merchantId: undefined,
            },
            requestId: null,
            points: null,
            tickets: null,
            payment: null,
          };
          // this.progressing = false;
        })
        .catch((e) => {
          console.error("failed to pay for order", e, e.response.data);
          let popConfig = {
            type: "error",
            // title: this.$i18n.t('錯誤'),
            message: this.$i18n.t("Payment failed"),
          };
          if (e.response?.data?.error?.paymentErrorMessage) {
            // const locale = this.$i18n.locale;
            // const code = e.response.data.error.paymentErrorCode;
            // const messageObj = e.response.data.error.errorMessages;
            // if (Object.keys(messageObj).includes(locale)) {
            //   this.errorMessage = messageObj[locale];
            // }
            popConfig.message = e.response.data.error.paymentErrorMessage;
          } else if (e.response?.data?.error?.message) {
            popConfig.message = e.response.data.error.message;
          }

          // if (e.response?.data?.error?.code === 'OR005') {
          //   this.lastErrorRequestId = e.response.data.error.meta.requestId;
          //   popConfig.okFunc = this.rollback;
          // }
          this.openPopup(popConfig);
          this.progressing = false;
        });
    },
    getUserPoints() {
      if (!this.isLoggedIn) return;
      fetchUserPoints(this.partnerCode, this.merchantId, this.userId, { shop_id: this.shopId })
        .then((response) => {
          console.log("done fetchUserPoints: " + JSON.stringify(response));
          if (response.data) {
            this.$store.commit("updateUserPoints", response.data);
          } else {
            this.$store.commit("updateUserPoints", []);
          }
        })
        .catch((e) => {
          console.error("fetchUserPoints failed", e);
          this.$store.commit("updateUserPoints", []);
        })
        .finally(() => {
          this.progressing = false;
        });
    },
  },
};
</script>
<!-- <style src="@/assets/css/vue-tel-input.css"></style> -->
<style scoped></style>
<!--
<style>
.card-form-input {
  width: 100%;
  height: 1.5rem;
  text-align: end;
  font-size: clamp(1.2rem, 5vw, 1.3rem);
  margin: 5px 0;
  padding: 5px;
}
</style>
-->
